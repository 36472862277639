@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Source+Sans+3:wght@400;600;700&family=Square+Peg&display=swap');

hmtl,
body,
#root,
.app,
.content {
    height: 100vh;
    width: 100%;
    font-family: 'Source Sans 3', sans-serif;
}

.app {
    display: flex;
    position: relative;
}

* ::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #e0e0e0;
}

/* handle */
::-webkit-scrollbar-thumb {
    background: #888;
}
/* handle on hover */
::-webkit-scrollbar-track:hover {
    background: #555;
}
